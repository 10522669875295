// polyfills
import "core-js/stable";
import "regenerator-runtime";
import "intersection-observer";
import Head from "next/head";
import { AppProps } from "next/app";
import { Fragment, useEffect } from "react";
import nprogress from "nprogress";

import "dayjs/locale/ja";
import "nprogress/nprogress.css";
import "yakuhanjp/dist/css/yakuhanmp.css";
import "yakuhanjp/dist/css/yakuhanjp.css";
import "~/styles/base/elements.scss";
import "~/styles/base/animation.scss";
import "~/styles/index.scss";
import { StyleSheet } from "aphrodite";
import { TranslationProvider, NavigationProvider } from "~/hooks";
import {
  PageFooter,
  PageMainLayout,
  PageSidebar,
  TranslateController
} from "~/components";

if (typeof window !== "undefined") {
  StyleSheet.rehydrate(window.__REHYDRATE_IDS);
}

nprogress.configure({
  showSpinner: false,
  speed: 400,
  minimum: 0.25
});

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  // progress bar
  if (process.browser) {
    nprogress.start();
  }
  useEffect(() => {
    nprogress.done();
  });

  //vh, vw set
  const setFillHeight = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
    document.documentElement.style.setProperty(
      "--vw",
      `${window.innerWidth * 0.01}px`
    );
  };
  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      let vw = window.innerWidth;
      window.addEventListener("resize", () => {
        if (vw === window.innerWidth) {
          return;
        }
        vw = innerWidth;
        setFillHeight();
      });
      setFillHeight();
    }
  }, []);

  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,viewport-fit=cover"
        />
        <link rel="icon" href="/favicon/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Shippori+Mincho:wght@400;700&family=Zilla+Slab:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <TranslationProvider>
        <NavigationProvider>
          <PageSidebar />
          <PageMainLayout renderComponent={<Component {...pageProps} />} />
          <PageFooter />
          <TranslateController fixed />
        </NavigationProvider>
      </TranslationProvider>
    </Fragment>
  );
};

export default App;
